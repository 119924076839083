:root {
    --color-link-default: hsl(248, 53%, 12%);
    --font-display-default: 'Lobster';
    --font-body-default: Lato;
    --fontsize-default: 1em;
    --font-family-headings: var(--font-display-default);

    --mobile-nav-toggle-color: black;
    --mobile-nav-toggle-size-factor: .7;

    --bp-tablet: 600px;
    --bp-desktop: 900px;

    --p1-3-hex: #34729e;
    // colors 1     
    --p1-1-hex: #4DA9EB;
    --p1-2-hex: #428fc7;
    --p1-4-hex: #275575;
    --p1-5-hex: #19374d;

    // colors 2
    --p2-1-hex: #041725;
    --p2-2-hex: #173C57;
    --p2-3-hex: #296088;
    --p2-4-hex: #4DA9EB;
    --p2-5-hex: #75BDF0;
    --p2-6-hex: #9DD0F4;
    --p2-7-hex: #C5E3F9;
    --p2-8-hex: #EDE6FD;

    --p3-0-hex: #ffffff;
    --p3-1-hex: #cddee9;
    --p3-2-hex: #9abdd4;
    --p3-3-hex: #649ebe;
    --p3-5-hex: #197fa9;
    --p3-6-hex: #1d5e7c;
    --p3-7-hex: #1b3f51;
    --p3-8-hex: #142226;
    --p3-9-hex: #000000;

    --custom-alpha: 1; // override at selector level
    --p1-1-hsla: hsla(205, 79%, 61%, var(--custom-alpha));
    --p1-2-hsla: hsla(205, 54%, 51%, var(--custom-alpha));
    --p1-3-hsla: hsla(204, 50%, 41%, var(--custom-alpha));
    --p1-4-hsla: hsla(204, 49%, 30%, var(--custom-alpha));
    --p1-5-hsla: hsla(205, 50%, 20%, var(--custom-alpha));

    // colors 2
    --p2-1-hsla: hsla(205, 80%, 8%, var(--custom-alpha));
    --p2-2-hsla: hsla(205, 58%, 21%, var(--custom-alpha));
    --p2-3-hsla: hsla(205, 53%, 34%, var(--custom-alpha));
    --p2-4-hsla: hsla(205, 79%, 61%, var(--custom-alpha));
    --p2-5-hsla: hsla(204, 80%, 70%, var(--custom-alpha));
    --p2-6-hsla: hsla(204, 79%, 78%, var(--custom-alpha));
    --p2-7-hsla: hsla(205, 81%, 87%, var(--custom-alpha));
    --p2-8-hsla: hsla(258, 85%, 94%, var(--custom-alpha));

    // colors 3
    --p3-0-hsla: hsla(0, 0%, 100%, var(--custom-alpha));
    --p3-1-hsla: hsla(203, 38%, 85%, var(--custom-alpha));
    --p3-2-hsla: hsla(203, 40%, 71%, var(--custom-alpha));
    --p3-3-hsla: hsla(201, 40%, 56%, var(--custom-alpha));
    --p3-4-hsla: hsla(197, 74%, 38%, var(--custom-alpha));
    --p3-5-hsla: hsla(198, 62%, 30%, var(--custom-alpha));
    --p3-6-hsla: hsla(199, 49%, 21%, var(--custom-alpha));
    --p3-7-hsla: hsla(193, 31%, 11%, var(--custom-alpha));
    --p3-8-hsla: hsla(0, 0%, 0%, var(--custom-alpha));
}


$primary-a0: hsl(0, 0%, 100%);
$primary-a10: hsl(205, 67%, 92% / .01);
$primary-a20: hsl(203, 66%, 83%);
$primary-a30: hsl(202, 68%, 73%);
$primary-a40: hsl(201, 68%, 63%);
$primary-a50: hsl(195, 100%, 43%);
$primary-a60: hsl(198, 74%, 38%);
$primary-a70: hsl(199, 62%, 30%);
$primary-a80: hsl(201, 50%, 21%);
$primary-a90: hsl(203, 37%, 12%);

$bp-tablet: 600px;
$bp-desktop: 600px;

/* Globals */
* {
    box-sizing: border-box;
}

body {
    font-family: var(--font-body-default);
    line-height: 1.75;
}

a {
    color: var(--color-link-default);
}

a:visited {
    //color: white;
}

p {
    margin-bottom: 1rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--font-family-headings);
    margin: 3rem 0 1.38rem; // NOTE(DON): probably fucking a lot of things up
    line-height: 1.3;
}

h1 {
    margin-top: 0;
    font-size: 3.052rem;
}

h2,
h1 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small,
.text_small {
    font-size: 0.8rem;
}

main {
    background-size: 20px 20px;
    background-color: #e5e5f7;
    opacity: 0.8;
    // background-image: linear-gradient(rgb(68 76 247 / 30%) 1px,
    //         transparent 1px),
    //     linear-gradient(to right,
    //         rgb(68 76 247 / 30%) 1px,
    //         #e5e5f7 1px);
}

.glf-mor-restaurant-menu-wrapper,
#cat1,
#cat2,
#cat3>div:nth-child(2)>*,
.bb-menu-item>p:nth-child(2),
#cat3 .bb-menu-item:nth-child(2) {
    display: none !important;
}



// .bb-menu {
//     display: grid;
//     grid-template-rows: 20vh;


//     >h2,
#bb-menu {
    width: 80%;
    margin: 0;

    padding: 0;
    justify-self: center;

}

h2 {
    margin: -47px 0 -7px 0;
    text-align: center;
    font-family: var(--font-display-default);
    font-size: 6rem;
}

#bb-menu {
    .bb-menu-category {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        >h3 {
            display: none;
        }

        .bb-menu-item {
            display: flex;
            flex-wrap: wrap;

            >h4 {
                flex-basis: 52vw;
                align-self: center;
                margin: 0;
                font-family: var(--font-display-default);
                font-size: 2rem;
            }

            p:nth-child(3) {
                flex-basis: 28vw;
                align-self: center;
                text-align: end;
            }

            p:nth-child(4) {
                margin: 0;
            }
        }
    }

    ul.tabs {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .call_to_action {
            grid-column-start: -3;
            grid-column-end: -1;
            text-align: center;

            label {

                // background-color: blue;
                // color: white;
            }
        }
    }
}

// }

.bb-home {
    position: relative;
    display: grid;
    // grid-template-rows: 8rem 29rem 41rem 22rem 29rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    // using fractional grid units doens't seem to be working out for me.
    grid-template-rows: 0.3fr 1fr 0.75fr 1.75fr 0fr;
    grid-template-rows: 6.0rem 154rem 107vh min-content auto;
    // background-color: #cddee9;
    // background-image: linear-gradient(
    //       180deg,
    //       hsl(0deg 0% 100%) 0%,
    //       hsl(0deg 47% 60%) 25%,
    //       hsl(0deg 47% 73%) 50%,
    //       hsl(215deg 29% 84%) 75%,
    //       hsl(215deg 30% 36%) 100%
    //  );
    background-image: linear-gradient(180deg, hsl(0, 0%, 100%) 0%, hsl(0, 43%, 50%) 4%, hsl(0, 43%, 66%) 50%, hsl(215, 29%, 84%) 74%, hsl(215, 30%, 36%) 100%);
    background-size: cover;

    &:before {
        content: ' ';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.15;
        display: block;
        filter: drop-shadow(2px 4px 6px black);

        background-size: 250%;
        background-image: url('../images/svg-logo.svg');
        background-repeat: no-repeat;

        background-color: $primary-a10;
        z-index: -1;
    }

    header {
        z-index: 991;
        width: 100%;
        // background-color: hsla(204, 40%, 72%, .28);
        margin-bottom: -.9rem;
        align-items: flex-end;

        img {
            width: 60vw;
            max-width: 300px;

        }

    }


    .sec-1 {
        gap: 1rem;
        display: grid;
        z-index: 990;
        flex-direction: column;
        align-self: center;
        padding: 0 0 1.75rem 0;
        // padding: 1.3rem 1rem 2.5rem 1rem;
        // background-color: hsl(222deg 62% 46% / 78%);
        color: white;
        align-self: normal;
        justify-content: flex-start;

        >* {
            width: 90%;
            margin: 0 auto;
        }

        // >*:nth-child(1),
        // *:nth-child(2) {
        //     width: 87%;
        //     margin: 0 auto;
        // }
        //
        .RENAME_h1specialemphasis {
            // padding-top: 2rem;
            margin-bottom: -.5rem;
            font-size: calc(var(--fontsize-default) + var(--fontsize-default) * 4.7);
            font-weight: 300;
            margin-top: 1rem;
            z-index: 1;
        }

        .RENAME_specialemphasis {
            line-height: 1.35;
            // margin-top: 18rem;

            span.specialemphasis {
                font-weight: bold;
            }
        }

        p {
            margin-top: 0;
            margin-bottom: 0;
            // color: white;
            line-height: 1.55;
            font-size: calc(var(--fontsize-default) + var(--fontsize-default) * 1);

            a {
                font-weight: 900;
                color: white;
            }
        }


        .michelin {
            // michelin display

            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-self: flex-end;
            justify-content: flex-end;
            margin-left: 0;
            margin-right: 0;
            background-color: var(--p3-5-hex);

            p {
                width: 90%;
                font-size: 2rem;
                line-height: 1.35;
                margin: 2rem auto;
                color: var(--p2-8-hex);

                span {
                    font-weight: bold;
                }
            }

            .banner-display {
                display: flex;
                width: 100%;

                picture {
                    flex-basis: 33.3%;
                }
            }
        }

        // mark 1.3 (cta pass) -> 2.5
        //
        // https://stackoverflow.com/questions/56109644/how-to-do-image-clipping-by-using-svg-shapes
        //
        // this is interesting.  


        // .special-text-container {
        //
        //
        //     background-color: #536ac4;
        //     width: 100%;
        //     display: flex;
        //     flex-direction: column;
        //     margin-top: 2rem;
        //
        //     p {
        //         background: rgba(255, 255, 255, .2);
        //         padding: 0.5rem 0 0.9rem .9rem;
        //         z-index: 1000000;
        //         width: 81%;
        //         align-self: flex-end;
        //     }
        //
        //     .shadowbar {
        //         margin-left: -5px;
        //         width: 100vw;
        //         height: 2.5rem;
        //         background-color: rgb(172 185 221);
        //         z-index: 10000000;
        //         // margin-top: -16px;
        //         margin-right: -10px;
        //
        //         clip-path: polygon(0 177%, 20% 0%, 0% 0%);
        //
        //         // .triangle {
        //         //     width: 2rem;
        //         //     height: 2rem;
        //         //     width: 0;
        //         //     height: 0;
        //         //     border-bottom: 29px solid transparent;
        //         //     border-right: 87px solid transparent;
        //         //     border-left: 76px solid #536ac4;
        //         //     margin-top: 0px;
        //         // }
        //     }
        // }

        .special-text-container {
            width: 100%;
            height: 15rem;
            background-color: white;
            margin: 0;
        }

        // new special-text-container
        //
        //



        .image-banner {
            display: flex;
            width: 125%;
            margin-left: -16%;

            overflow: hidden;

            >* {
                margin: 0;
                height: 200%;
                width: 100%;
            }

            picture {
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    width: 37.5%;
                    margin-right: -7.5%;
                    -webkit-clip-path: polygon(25% 0, 100% 0, 75% 100%, 0 100%);
                    clip-path: polygon(25% 0, 100% 0, 75% 100%, 0 100%);
                }

                img {
                    width: 334%;
                    // height: 105%;
                    object-fit: contain;
                }

                &:nth-child(1) {
                    img {
                        object-position: -222px 2px;
                    }
                }

                &:nth-child(2) {
                    img {
                        object-position: -222px;
                    }
                }

                &:nth-child(3) {
                    img {
                        object-position: -187px;
                    }
                }
            }


            // img {
            //     width: 334%;
            //     height: 105%;
            //     object-fit: contain;
            //
            //     &:nth-child(1) {
            //         object-position: -112px 2px;
            //
            //     }
            //
            //     &:nth-child(2) {
            //         object-position: -162px 6px;
            //     }
            //
            //     &:nth-child(3) {
            //         object-position: -67%;
            //     }
            // }
        }
    }

    .sec-1 {

        grid-template-rows: min-content min-content min-content 14rem;
        overflow: hidden;

        .recognition {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-around;

            >* {
                width: 90%;

            }


            p {
                margin: 0 auto;
                font-size: 1rem;

                &:nth-child(1) {
                    font-size: 1.35rem;
                    line-height: 1.25;
                }
            }

            .RENAME_specialemphasis {
                font-weight: bold;
            }

            .banner-display {
                display: flex;
                width: 100%;

                picture {
                    // flex-basis: 33.3%;

                    img {
                        width: 100%;
                    }
                }
            }

            //     picture {
            //         width: 50%;
            //
            //         img {
            //             width: 100%;
            //         }
            //     }
            // }

        }

        .sec-2 {
            align-self: start;
        }
    }

    .sec-1-2 {

        /* spacing rules */
        >*:nth-child(1) {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        >*:nth-child(2) {}

        >*:nth-child(3) {}

        >*:nth-child(4) {}

        >*:nth-child(5) {
            margin-top: 3rem;
        }

        width: 100%;
        margin-right: auto;
        margin-left: auto;
        // padding: 0.7rem 1rem 1.2rem 1rem;
        z-index: 3000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

        >* {
            width: 85%;
            margin: 0 auto;
            align-self: flex-end;
        }

        >*:nth-child(1) {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }


        // .michelin {
        //     // michelin display
        //
        //     width: 100%;
        //     display: flex;
        //     flex-wrap: wrap;
        //     align-self: flex-end;
        //     justify-content: flex-end;
        //     margin-left: 0;
        //     margin-right: 0;
        //     background-color: var(--p3-5-hex);
        //
        //     p {
        //         width: 90%;
        //         font-size: 2rem;
        //         line-height: 1.35;
        //         margin: 2rem auto;
        //         color: var(--p2-8-hex);
        //
        //         span {
        //             font-weight: bold;
        //         }
        //     }
        //
        //
        //     .banner-display {
        //         display: flex;
        //         width: 100%;
        //
        //         picture {
        //             flex-basis: 33.3%;
        //         }
        //     }
        // }

        h3 {
            color: #1b3f51;
            font-size: 3rem;
            // margin-bottom: 2rem;
        }

        .quote {
            background-color: white;
            padding: 0 1rem 0 1rem;

            p {
                font-size: 1.2rem;

                &:last-child {
                    font-size: 1.1rem;
                    font-style: italic;
                    color: #1d5e7c;
                }
            }
        }

        img {
            width: 100%
        }
    }

    .primary-display {

        // // debug code
        // >* {
        //     // border: 1px solid red;
        //
        //     >* {
        //         border: 1px solid blue;
        //
        //         >* {
        //             border: 1px solid green;
        //         }
        //     }
        // }

        * {
            // I STILL DON'T UNDERSTAND HOW THE FUCK I SHOULD BE SIZING ELEMENTS!!!!!!!!!!!!!!
            margin: 0;
        }

        .slide-projection {
            height: 381px;
            position: relative;
            overflow: hidden;
            // align-self: end; // ?

            figure {
                position: absolute;
                top: 0;

                picture {
                    img {
                        object-fit: contain;
                    }
                }


            }
        }


    }

    .sec-2 {
        // slider
        /*
     * NOTE(DON):The JS file is where the colors are declared for the figcation title array
     * grep: // NOTE(DON): this is where the color for the slider titles are set. 
     * */
        height: 320px;
        position: relative;
        overflow: hidden;
        align-self: end;

        figcaption {
            z-index: 1;
            width: 100%;
            position: relative; // defaults to top of the container

            padding: 0 1rem 0 0;
            margin: 0 0 -10px 0;

            background-color: rgb(243 214 142 / 60%);

            text-align: right;
            text-align: center;

            font-family: var(--font-display-default);
            font-size: 1.5rem;
        }

        figure {
            transition: opacity .5s ease-in-out;
            height: 100%;
            /* Make sure the slides take the full height of the container */
            position: absolute;

            margin: 0;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            picture {
                order: 2;
                width: 100%;

                img {
                    width: 100%;
                }
            }

            .figbottom {
                width: 100vw;
                order: 2;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                p:nth-of-type(1) {
                    width: 62vw;
                    margin: -1rem 0 42px 1rem;
                    background-color: white;

                    text-align: center;
                    font-size: 1.3rem;
                }

                p:nth-of-type(2) {
                    margin-right: 14px;
                    margin-top: -39px;

                    font-size: 5rem;
                    color: rgb(44 153 189);
                }
            }
        }

        .slide-controls {
            max-width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            background-color: rgba(243, 214, 142, 0.6);
            z-index: 2000;
            margin-top: -2.9rem;
            position: absolute;
            bottom: 0;

            a {
                max-width: 20%;

                // text-align: center;
                &:last-child {
                    text-align: right;
                }
            }

            p {
                font-family: var(--font-display-default);
                font-size: 1rem;
                align-self: center;
                margin: 0;
            }

            img {
                max-width: 100%;
            }
        }
    }

    .sec-3 {


        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;

        gap: 18px;

        .orderonline {
            width: max-content;
            height: max-content;
            align-self: center;

            padding: 1rem 1rem 1rem 1rem;
            margin: 0 0 0 0;

            background-color: #7cacf8;

            color: white;
            font-size: 3rem;
            font-family: var(--font-body-default);
            font-style: italic;
        }

        >p {
            width: 60vw;
            align-self: center;
            margin: 0;
        }

        .socialsgrid {
            width: 60vw;
            align-self: center;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            >p {
                align-self: center;
                margin: 0;
            }
        }
    }

    .bb-photos {
        display: grid;
        grid-template-rows: 20vh;

        >h2 {
            width: 80%;
            align-self: center;
            text-align: center;
            justify-self: center;
        }

        .sec-1 {
            display: flex;
            flex-direction: column;
            align-self: center;
            justify-self: center;

            gap: 3rem;

            figure {
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
                background-color: grey;

                figcaption {
                    margin-bottom: -23px;
                    font-size: 2rem;
                    background: orange;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }

                picture {
                    align-self: center;
                }
            }
        }
    }

    .bb-contact {
        display: grid;
        grid-template-rows: 20vh;


        .sec-1 {
            width: 90%;
            margin: 0 auto;
            padding: 0 1rem;

            background-color: white;



            h4:first-of-type {
                margin-top: 1.7rem;
            }

            h4 {
                font-family: var(--font-display-default);

            }

            h5 {
                margin-top: -.8rem;
            }
        }

        .map {
            margin: 0;
            padding: 0;
            width: 100vw;
            height: 22rem;
            background-color: grey;

            margin-top: 3rem;

            #map {
                width: 100%
            }
        }

        img {
            width: 100%;
        }
    }

    header {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        // background-color: $primary-a30;



        >img {
            max-width: 70vw;
        }

        a:visited {
            // color: var(--mobile-nav-toggle-color);
            // color: white;
            // color: hsla(204, 39%, 86%, .7)
        }
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    /* Button styling */

    .menu-toggle {
        display: inline-block;
        line-height: 1em;
        font-size: calc(var(--fontsize-default) + var(--fontsize-default) * var(--mobile-nav-toggle-size-factor));
        /* dial on hamburger menu size */
        // NOTE(DON): leaver for menu icon
        margin-top: .6rem;
        color: #ac2c2c;

        // &:focus {
        //   color: black;
        //   z-index: 3000;
        // }
        //
        // &:target {
        //   color: black;
        //   z-index: 2000;
        // }
        //
        // &:visited {
        //   color: black;
        // }

    }

    /*
 Default styles + Mobile first
 Offscreen menu style
*/
    .main-menu {
        position: absolute;
        display: none;
        left: -200px;
        top: 0;
        height: 100%;
        overflow-y: scroll;
        overflow-x: visible;
        transition: left 0.3s ease,
            box-shadow 0.3s ease;
        z-index: 999;

        ul {
            list-style: none;
            margin: 0;
            padding: 2.5em 0 0;
            /* Hide shadow w/ -8px while 'closed' */
            -webkit-box-shadow: -8px 0 8px rgba(0, 0, 0, .5);
            -moz-box-shadow: -8px 0 8px rgba(0, 0, 0, .5);
            box-shadow: -8px 0 8px rgba(0, 0, 0, .5);
            min-height: 100%;
            width: 200px;
            background: #3f6383;
        }

        a {
            z-index: 1000;
            display: block;
            padding: .75em 15px;
            line-height: 1em;
            font-size: 1.25em;
            font-weight: 700;
            color: white;

            text-decoration: none;
            border-bottom: 1px solid hsl(208 21% 30% / 1);
        }

        li:first-child a {
            border-top: 1px solid #383838;
        }

        a:hover,
        a:focus {
            background: #333;
            text-decoration: underline;
        }

        .menu-close {
            position: absolute;
            right: 0;
            top: 0;
        }


        /*
 On small devices, allow it to toggle...
*/
        /*
 :target for non-JavaScript
 [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
*/
        &:target,
        &[aria-expanded="true"] {
            display: block;
            left: 0;
            outline: none;
            -moz-box-shadow: 3px 0 12px rgba(0, 0, 0, .25);
            -webkit-box-shadow: 3px 0 12px rgba(0, 0, 0, .25);
            box-shadow: 3px 0 12px rgba(0, 0, 0, .25);
        }

        &:target .menu-close,
        &[aria-expanded="true"] .menu-close {
            z-index: 1001;
        }

        &:target ul,
        &[aria-expanded="true"] ul {
            position: relative;
            z-index: 1000;
        }

        /* 
 We could us `.main-menu:target:after`, but
 it wouldn't be clickable.
*/
        &:target+.backdrop,
        &[aria-expanded="true"]+.backdrop {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 998;
            background: hsl(203deg 100% 15% / 81%);
            cursor: default;
        }
    }





    @supports (position: fixed) {

        .main-menu,
        .main-menu:target+.backdrop,
        .main-menu[aria-expanded="true"]+.backdrop {
            position: fixed;
        }
    }

    .page-content {
        max-width: 700px;
        margin: 32px auto;
        padding: 32px;
        background: #fff;
    }

    a {
        color: #21D4FD;
        transition: all 0.3s;
    }

    a:hover {
        color: #B721FF;
    }

    .tabbed {
        overflow-x: hidden;
        /* so we could easily hide the radio inputs */
        margin: 32px 0;
        padding-bottom: 16px;
        border-bottom: 1px solid #ccc;
    }

    .tabbed [type="radio"] {
        /* hiding the inputs */
        display: none;
    }

    .tabs {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        list-style: none;
        padding: 0;
        border-bottom: 1px solid #ccc;
    }

    .tab>label {
        // display: block;
        // margin-bottom: -1px;
        // padding: 12px 15px;
        // border: 1px solid #ccc;
        // background: #eee;
        // color: #666;
        // font-size: 12px;
        // font-weight: 600;
        // text-transform: uppercase;
        // letter-spacing: 1px;
        // cursor: pointer;
        // transition: all 0.3s;
        // z-index: 1000;
    }

    .tab:hover label {
        border-top-color: #333;
        color: #333;
    }



    /* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
    // .tabbed [type="radio"]:nth-of-type(1):checked~.tabs .tab:nth-of-type(1) label,
    // .tabbed [type="radio"]:nth-of-type(2):checked~.tabs .tab:nth-of-type(2) label,
    // .tabbed [type="radio"]:nth-of-type(3):checked~.tabs .tab:nth-of-type(3) label,
    // .tabbed [type="radio"]:nth-of-type(4):checked~.tabs .tab:nth-of-type(4) label,
    // .tabbed [type="radio"]:nth-of-type(5):checked~.tabs .tab:nth-of-type(5) label,
    // .tabbed [type="radio"]:nth-of-type(6):checked~.tabs .tab:nth-of-type(6) label,
    // .tabbed [type="radio"]:nth-of-type(7):checked~.tabs .tab:nth-of-type(7) label,
    // .tabbed [type="radio"]:nth-of-type(8):checked~.tabs .tab:nth-of-type(8) label {
    //     border-bottom-color: #fff;
    //     border-top-color: #B721FF;
    //     background: #fff;
    //     color: #222;
    // }

    .tabbed [type="radio"]:nth-of-type(1):checked~.tab-content:nth-of-type(1),
    .tabbed [type="radio"]:nth-of-type(2):checked~.tab-content:nth-of-type(2),
    .tabbed [type="radio"]:nth-of-type(3):checked~.tab-content:nth-of-type(3),
    .tabbed [type="radio"]:nth-of-type(4):checked~.tab-content:nth-of-type(4),
    .tabbed [type="radio"]:nth-of-type(5):checked~.tab-content:nth-of-type(5),
    .tabbed [type="radio"]:nth-of-type(6):checked~.tab-content:nth-of-type(6),
    .tabbed [type="radio"]:nth-of-type(7):checked~.tab-content:nth-of-type(7),
    .tabbed [type="radio"]:nth-of-type(8):checked~.tab-content:nth-of-type(8) {
        display: flex !important;
        // display: block;
    }

    #bb-menu>ul>li:nth-child(1),
    #bb-menu>ul>li:nth-child(2) {
        display: none;
    }

    #bb-menu {
        display: flex;
        flex-direction: column;

        input {
            width: 75%;
        }
    }

    /*
 Larger screen styling
 Horizontal menu

 NOTE(DON): it looks like the right way to define styles at larger viewport widths is to use the min-width media query to change the appearance without effecting the way that the layout looks on the smaller sizes.  Progressive enhancement
*/
    @media (min-width: 768px) {

        .menu-toggle,
        .main-menu .menu-close {
            display: none;
        }

        /* Undo positioning of off-canvas menu */
        .main-menu {
            position: relative;
            left: auto;
            top: auto;
            height: auto;
            display: block;

            ul {
                display: flex;

                /* Undo off-canvas styling */
                padding: 0;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                height: auto;
                width: auto;
                background: none;
            }

            a {
                color: #06c;
                color: black;
                border: 0 !important;
                /* Remove borders from off-canvas styling */
            }

            a:hover,
            a:focus {
                background: none;
                /* Remove background from off-canvas styling */
                color: #c00;
            }
        }
    }


    // .page-content {
    //     max-width: 700px;
    //     margin: 32px auto;
    //     padding: 32px;
    //     background: #fff;
    // }

    // a {
    //     color: #21D4FD;
    //     transition: all 0.3s;
    // }

    // a:hover {
    //     color: #B721FF;
    // }

    // .tabbed {
    //     overflow-x: hidden;
    //     /* so we could easily hide the radio inputs */
    //     margin: 32px 0;
    //     padding-bottom: 16px;
    //     border-bottom: 1px solid #ccc;
    // }

    // .tabbed [type="radio"] {
    //     /* hiding the inputs */
    //     display: none;
    // }

    // .tabs {
    //     display: flex;
    //     flex-wrap: wrap;
    //     align-items: stretch;
    //     list-style: none;
    //     padding: 0;
    //     border-bottom: 1px solid #ccc;
    // }

    // .tab>label {
    //     display: block;
    //     margin-bottom: -1px;
    //     padding: 12px 15px;
    //     border: 1px solid #ccc;
    //     background: #eee;
    //     color: #666;
    //     font-size: 12px;
    //     font-weight: 600;
    //     text-transform: uppercase;
    //     letter-spacing: 1px;
    //     cursor: pointer;
    //     transition: all 0.3s;
    // }

    // .tab:hover label {
    //     border-top-color: #333;
    //     color: #333;
    // }

    //  #bb-menu .bb-menu-category.tab-content {
    //     display: none;
    //     color: #777;
    // }

    // /* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
    // .tabbed [type="radio"]:nth-of-type(1):checked~.tabs .tab:nth-of-type(1) label,
    // .tabbed [type="radio"]:nth-of-type(2):checked~.tabs .tab:nth-of-type(2) label,
    // .tabbed [type="radio"]:nth-of-type(3):checked~.tabs .tab:nth-of-type(3) label,
    // .tabbed [type="radio"]:nth-of-type(4):checked~.tabs .tab:nth-of-type(4) label,
    // .tabbed [type="radio"]:nth-of-type(5):checked~.tabs .tab:nth-of-type(5) label,
    // .tabbed [type="radio"]:nth-of-type(6):checked~.tabs .tab:nth-of-type(6) label,
    // .tabbed [type="radio"]:nth-of-type(7):checked~.tabs .tab:nth-of-type(7) label,
    // .tabbed [type="radio"]:nth-of-type(8):checked~.tabs .tab:nth-of-type(8) label {
    //     border-bottom-color: #fff;
    //     border-top-color: #B721FF;
    //     background: #fff;
    //     color: #222;
    // }

    // .tabbed [type="radio"]:nth-of-type(1):checked~.tab-content:nth-of-type(1),
    // .tabbed [type="radio"]:nth-of-type(2):checked~.tab-content:nth-of-type(2),
    // .tabbed [type="radio"]:nth-of-type(3):checked~.tab-content:nth-of-type(3),
    // .tabbed [type="radio"]:nth-of-type(4):checked~.tab-content:nth-of-type(4),
    // .tabbed [type="radio"]:nth-of-type(5):checked~.tab-content:nth-of-type(5),
    // .tabbed [type="radio"]:nth-of-type(6):checked~.tab-content:nth-of-type(6),
    // .tabbed [type="radio"]:nth-of-type(7):checked~.tab-content:nth-of-type(7),
    // .tabbed [type="radio"]:nth-of-type(8):checked~.tab-content:nth-of-type(8) {
    //     display: flex !important;
    // }

    // #bb-menu>ul>li:nth-child(1),
    // #bb-menu>ul>li:nth-child(2) {
    //     display: none;
    // }

    // #bb-menu {
    //     display: flex;
    //     flex-direction: column;

    //     input {
    //         width: 75%;
    //     }
    // }


    /* Reskinning Woocommerce template  */
    .woocommerce.woocommerce-shop {}

    .woocommerce.single-product {}

    .woocommerce-cart {}

    .woocommerce-checkout {}

    .woocommerce-order-received {}

    /* end woocommerce */

    @media (min-width: $bp-tablet) {
        // Tablet styles
        // Adjust as needed
        //

        .bb-home {
            grid-template-rows: 8rem 24rem 31rem 22rem 29rem;

            // max-height: 167px;
            header {
                background-color: hsla(204, 40%, 72%, .28);
                margin-bottom: -.9rem;

                img {
                    // width: 50vw;
                    // max-width: 300px;
                }
            }
        }


    }

    @media (min-width: $bp-desktop) {
        // Desktop styles
        // Adapt to larger screens
    }
}


/* new approach */

/* content */
.css_handler:before {
    content: '';
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    opacity: 0.15;
    display: block;
    filter: drop-shadow(2px 4px 6px black);


    background-repeat: no-repeat;

    background-color: $primary-a10;
}

.css_handler:before {
    content: 'SEE THE GUIDE';
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    // opacity: 0.15;
    display: block;
}

.css_handler_2:after {
    content: ",";
    background-image: url(http://localhost:3000/images/_icons/sacramento-bee-logo-sm.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    max-width: 100%;
    position: relative;
    left: 0;
    top: 0;
    display: block;

}

.margin_centered {
    margin: 0 auto !important;
}

.textalign_centered {
    text-align: center;
}

.margintop {
    margin-top: var(--margintop) !important;
}

.marginbottom {
    margin-bottom: var(--marginbottom) !important;
}

.paddingtop {
    padding-top: var(--padding-top) !important;
}

.paddingbottom {
    padding-bottom: var(--paddingbottom) !important;
}

._100vw_wide {
    width: 100vw;
}

._100per_wide {
    width: 100%;
}

._90per_wide {
    width: 90%;
}

.fitcontent_wide {
    width: fit-content;
}

a ._33vh_high {
    height: 33vh;
}

._0_high {
    height: 0;
}

.high {
    height: var(--high);
}

.position_relative {
    position: relative
}

.bg_size_contain {
    background-size: contain;
}

.bg_size_cover {
    background-size: cover;
}

.bg_norepeat {
    background-repeat: no-repeat;
}

._40vw_maxwide {
    max-width: 40vw;
}

.pad1 {
    padding: 1rem;
}

.pad2 {
    // call to action
    padding: .5rem .25rem;
}

.color_dark0 {
    color: #611919 !important;
}

.color_dark0 {
    color: #ac2c2c !important;
}

p.font_small {
    font-size: large !important;
}

.make_circle {
    clip-path: circle(var(--factor));
    shape-outside: circle(var(--factor));
}

.flex_row {
    display: flex;
    flex-direction: row;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

.flex_alignitems_end {
    align-items: flex-end !important;
}

.flex_alignitems_end {
    align-items: center !important;
}

.flex_justifycontent_center {
    justify-content: center !important;
}

.float_left {
    float: left;
}

.float_right {
    float: right;
}

.ft_size {
    font-size: calc(var(--fontsize-default) + var(--fontsize-default) * var(--ft_size_factor)) !important;
}

.ft_display {
    font-family: var(--font-display-default);
}

.small_caps {
    font-variant-caps: small-caps;
}

.ft_body {
    font-family: var(--font-body-default);
}

.bg_white_alph {
    background-color: rgba(255, 255, 255, var(--alpha));
}

.relative {
    position: relative;
}

.top {
    top: var(--top);
}

._3_4_aspect_ratio_crop {
    padding-top: calc(100% * (300 / 400));
}

.bold {
    font-weight: 900;
}

.bb-menu-category.tab-content {
    display: none !important;
    // color: #777;
}

/*


    <div class="primary-display">
      <div class="titles-controls">
        <span class="title-text">Breakfast | </span>
        <span class="title-text">Brunch | </span>
        <span class="title-text">Lunch</span>
      </div>
       
      <div class="slide-projection">
        <figure>
          <picture>
            <source srcset="<?php echo get_bloginfo('url') ?>/images/_RDV5514Edit/_RDV5514Edit-851-20.png" media="(min-width: 500px)">
            <img src="<?php echo get_bloginfo('url') ?>/images/_RDV5514Edit/_RDV5514Edit-426-10.png">
          </picture>
        </figure>
        <figure> 
          <picture>
            <source srcset="<?php echo get_bloginfo('url') ?>/images/drinksbloodymary/drinksbloodymary-851-20.png" media="(min-width: 500px)">
            <img src="<?php echo get_bloginfo('url') ?>/images/drinksbloodymary/drinksbloodymary-426-10.png">
          </picture>
        </figure> 
        <figure>
          <picture>
            <source srcset="<?php echo get_bloginfo('url') ?>/images/_RDV2541Edit/_RDV2541Edit-851-20.png" media="(min-width: 500px)">
            <img src="<?php echo get_bloginfo('url') ?>/images/_RDV2541Edit/_RDV2541Edit-426-10.png">
          </picture>
        </figure>
      </div>
*/


.wide {
    width: var(--wide) !important;
}

.zindex {
    z-index: var(--zindex);
}

.gap {
    gap: var(--gap);
}

.underline {
    text-decoration: underline;
}

.underline {
    text-decoration: underline;
}
